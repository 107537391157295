import { useLoaderData, useLocation, useSearchParams } from "@remix-run/react";
import { useMemo } from "react";
import { Configure, InstantSearch } from "react-instantsearch";
import { Container, Flex } from "sparta";
import { PostHits } from "~/components/search/PostHits";
import { ExportProvider } from "~/context/ExportContext";
import { homeClient } from "~/sdk/qs3/typesenseClient";
import { urlToSearchState } from "~/utils/search";
import AdvertisementComponent from "../../../../components/layout/Advertisement";
import RefinementLists from "./refinementLists";

export default function GridFeed() {
  const { url, advertisements, user } = useLoaderData<any>();
  const location = useLocation();
  const searchState = useMemo(() => urlToSearchState(location), [location.search]);

  const [searchParams] = useSearchParams();
  const filters = {
    category: searchParams.get("category"),
    agency: searchParams.get("agency"),
  };
  const platformFromUrl = location.pathname.split("/")[1];
  
  // Check if user is logged in - show ads only for non-logged-in users
  const showAds = !user || user.anonymous;

  return (
    <InstantSearch
      searchClient={homeClient}
      indexName="posts"
      initialUiState={searchState as any}
      future={{
        preserveSharedStateOnUnmount: true,
      }}
      stalledSearchDelay={100}
      routing={true}
    >
      <Configure
        facetsRefinements={{
          platforms: platformFromUrl ? [platformFromUrl] : [],
          ...(filters.category ? { postType: [filters.category] } : {}),
          ...(filters.agency ? { agency: [filters.agency] } : {}),
        }}
      />
      <ExportProvider initialUrl={url}>
        <Container>
          {showAds && advertisements?.length > 0 ? <AdvertisementComponent advertisement={advertisements[0]} /> : null}
          <Flex direction="column" gap="4">
            <RefinementLists />
            <PostHits />
          </Flex>
        </Container>
      </ExportProvider>
    </InstantSearch>
  );
}
